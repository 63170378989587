import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

const SBMPage = () => {

  return (
    <Layout>
      <SEO title="Small Business Marketing" />


    </Layout>
  )
}

export default SBMPage